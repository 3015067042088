<template>
  <section class="max-w-screen-2xl mx-auto pt-6 px-2 sm:px-6 lg:px-8">
    <div class="container flex flex-row items-center justify-start">
      <div class="flex items-center">
        <div class="mr-2">
          <img src="/icon-title-submissions.svg" class="h-5 w-5" />
        </div>
        <div>
          <h1 class="font-bold text-WADARKBLUE-500 text-base uppercase">
            {{ $t("add-submission") }}
          </h1>
        </div>
      </div>
    </div>
  </section>

  <section class="max-w-screen-2xl mx-auto px-2 py-6 sm:px-6 lg:px-8">
    <div class="flex items-center justify-between">
      <div class="flex items-center">
        <button
          @click="$router.back()"
          class="
            bg-WAORANGE-500
            hover:bg-WAORANGE-400
            mr-2
            px-4
            py-2
            rounded-lg
            text-white text-xs
            uppercase
          "
        >
          {{ $t("back") }}
        </button>
      </div>
    </div>
  </section>

  <section class="max-w-screen-2xl mx-auto px-2 sm:px-6 lg:px-8">
    <div
      class="
        bg-white
        flex
        justify-center
        lg:px-4
        max-w-screen-2xl
        p-6
        rounded-lg
        shadow-2xl shadow-gray-100
      "
    >
      <div class="w-96">
        <submission-form
          @onSuccess="$router.push(`/submissions`)"
          onSuccess="true"
        ></submission-form>
      </div>
    </div>
  </section>
</template>

<script>
import SubmissionForm from "../components/submissions/SubmissionForm.vue";
import { mapGetters } from "vuex";

export default {
  components: {
    SubmissionForm,
  },
  ...mapGetters(["currentUser"]),
  isAdminOrEdit() {
    return (
      this.currentUser &&
      (this.currentUser?.role?.submission?.admin ||
        this.currentUser?.role?.submission?.edit)
    );
  },
};
</script>
